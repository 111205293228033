<template>
    <div class="container">
        <div class="banner">
            <img :src="banner">
        </div>
        <div class="hot">
            <div class="subTitle">热门资讯</div>
            <div class="hot_content">
                <div class="hot_list">
                    <div class="hot_list_item" v-for="(item,index) in hotList" :key="index" @click="changToDetail(item.article_id)">
                        <span class="num first" v-if="index+1 == 1">{{ index+1 }}.</span>
                        <span class="num second" v-else-if="index+1 == 2">{{ index+1 }}.</span>
                        <span class="num third" v-else-if="index+1 == 3">{{ index+1 }}.</span>
                        <span class="num" v-else>{{ index+1 }}.</span>
                        <span class="label">{{ item.title }}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="new">
            <div class="subTitle">最新活动</div>
            <div class="news_list">
                <div class="news_list_item" v-for="(item,index) in newList" :key="index" @click="changToDetail(item.article_id)">
                    <img v-lazy="item.cover_image">
                    <div class="info">
                        <span class="title">{{ item.title }}</span>
                        <span>{{ formatData(item.updated_at) }}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="tab">
            <div class="btn_list">
                <div class="btn_list_item" v-for="(item,index) in btnList" :key="index" :class="item.active?'active':''" @click="changeTab(item,index)">{{ item.label }}</div>
            </div>
            <div class="card_list">
                <div class="card_list_item" v-for="(item,index) in newsList" :key="index" @click="changToDetail(item.article_id)">
                    <div class="item_l">
                        <div class="title">{{ item.title }}</div>
                        <div class="intro">{{ item.summary }}</div>
                        <div class="date">{{ formatData(item.updated_at) }}</div>
                    </div>
                    <div class="item_r">
                        <img v-lazy="item.cover_image">
                    </div>
                </div>
            </div>
        </div>
        <mobile-Loading v-if="isLoading"/>
    </div>
</template>
<script>
import { getNews,getNewsBanner } from '@/api/news'
import moment from 'moment';
export default {
    data() {
        return {
            query: {
                page: 1,
                per_page: 10,
                category: '运东新闻',
                is_hot: 0
            },
            total: 0,
            newList: [],
            hotList: [],
            newsList: [],
            btnList: [
                {
                    label: '运东新闻',
                    active: true
                },
                {
                    label: '行业新闻',
                    active: false
                },
                {
                    label: '社会政策',
                    active: false
                }
            ],
            //距离底部的距离
            offBottom: 0,
            isEnd: false,
            isLoading: true,
            banner: ''
        }
    },
    mounted() {
        this.initData()
        this.getBanner()
        let hotParam = {
            page: 1,
            per_page: 5,
            category: '',
            is_hot: 1
        }
        this.initRightData(hotParam,'hotList')
        let newParam = {
            page: 1,
            per_page: 3,
            category: '最新活动',
            is_hot: 0
        }
        this.initRightData(newParam,'newList')

        window.addEventListener('scroll', this.handleScroll)
        this.offBottom = this.$store.state.distanceToBottom
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.handleScroll)
    },
    methods: {
        async initData(isPage = false) {
            try {
                if(!this.isEnd) {
                    this.isEnd = true
                    const res = await getNews(this.query)
                    this.total = res.total
                    if(!isPage) {
                        this.newsList = res.data;
                    }else {
                        this.newsList = [...this.newsList,...res.data]
                    }
                    this.query.page = this.query.page + 1
                    this.isEnd = false
                }
            }finally {
                this.isLoading = false
            }
        },
        async getBanner() {
            const res = await getNewsBanner()
            if(res.code == 200) {
                this.banner = res.data.banner
            }
        },
        async initRightData(query,type) {
            const res = await getNews(query)
            if(res.code == 200) {
                this[type] = res.data
            }
        },
        changeTab(item,i) {
            this.btnList.forEach((item,index) => {
                if(i == index) {
                    item.active = true
                }else {
                    item.active = false
                }
            })
            this.query.category = item.label
            this.query.page = 1
            this.initData()
        },
        changToDetail(id) {
            this.$router.push(`/newsDetail?id=${id}`)
            window.localStorage.setItem('routeTitle','新闻详情')
        },
        handleScroll() {
            const scrollTop = document.documentElement.scrollTop // 滚动条距离顶部的位置
            const clientHeight = document.documentElement.clientHeight // 可视区域的高度
            const scrollHeight = document.documentElement.scrollHeight // 页面的总高度
            const distanceToBottom = scrollHeight - scrollTop - clientHeight // 滚动条距离底部的位置
            if(distanceToBottom <= this.offBottom) {
                if(this.total > (this.query.page - 1) * this.query.per_page) {
                    this.initData(true)
                }
            }
        }
    },
    computed: {
        formatData() {
            return (date) => {
                return moment(date).format('YYYY-MM-DD')
            }
        }
    }
}
</script>
<style scoped lang="scss">
.first {
    color: #FF4D16!important;
}
.second {
    color: #FF8416!important;
}
.third {
    color: #FFB516!important;
}
.subTitle {
    color: #333333;
    font-size: 32px;
    font-weight: bold;
    margin: 32px 0;
}
.container {
    background: #f5f5f5;
    .banner {
        img {
            width: 100%;
            height: 400px;
            object-fit: cover;
        }
    }
    .hot {
        padding: 0 30px;
        box-sizing: border-box;
        &_content {
            background: #FFFFFF;
            border-radius: 6px;
            padding: 40px 30px;
            box-sizing: border-box;
            .hot_list {
                font-size: 28px;
                color: #333333;
                display: flex;
                flex-direction: column;
                row-gap: 40px;
                &_item {
                    display: flex;
                    column-gap: 20px;
                    transition: all 0.3s ease;
                    .num {
                        font-size: 30px;
                        font-weight: bold;
                        color: #CCCCCC;
                    }
                    .label {
                        width: 100%;
                        display: inline-block;
                        align-items: center;
                        white-space: nowrap; /* 禁止文本换行 */
                        overflow: hidden; /* 隐藏超出显示范围的内容 */
                        text-overflow: ellipsis; /* 显示省略号 */
                    }
                    &:active {
                        color: #ffa71d;
                    }
                }
            }
        }
    }
    .new {
        padding: 10px 30px 20px 30px;
        box-sizing: border-box;
        .news_list {
            width: 100%;
            overflow-x: scroll;
            display: flex;
            column-gap: 18px;
            &::-webkit-scrollbar {
                width: 0;
                background: transparent;
            }
            &_item {
                flex-shrink: 0;
                width: 320px;
                border-radius: 6px;
                overflow: hidden;
                background: #fff;
                img {
                    width: 100%;
                    height: 220px;
                }
                .info {
                    padding: 20px;
                    box-sizing: border-box;
                    color: #999999;
                    font-size: 24px;
                    display: flex;
                    flex-direction: column;
                    row-gap: 20px;
                    .title {
                        color: #333333;
                        font-size: 28px;
                        font-weight: bold;
                        white-space: nowrap; /* 禁止换行 */
                        overflow: hidden; /* 隐藏溢出内容 */
                        text-overflow: ellipsis; /* 显示省略号 */
                    }
                }
            }
        }
    }
    .tab {
        padding: 0 30px 50px 30px;
        box-sizing: border-box;
        .btn_list {
            display: flex;
            align-items: center;
            justify-content: space-between;
            &_item {
                font-size: 32px;
                color: #999999;
                font-weight: bold;
                transition: all 0.3s ease;
            }
            .active {
                color: #333333;
                position: relative;
                &::after {
                    content: '';
                    width: 130px;
                    height: 8px;
                    border-radius: 4px;
                    background: #FFA71D;
                    position: absolute;
                    left: 50%;
                    bottom: -20px;
                    transform: translateX(-50%);
                }
            }
        }
        .card_list {
            margin-top: 50px;
            display: flex;
            flex-direction: column;
            row-gap: 24px;
            &_item {
                width: 100%;
                padding: 30px 20px;
                box-sizing: border-box;
                border-radius: 6px;
                background: #fff;
                display: flex;
                column-gap: 32px;
                align-items: center;
                .item_l {
                    flex: 1;
                    color: #999999;
                    .title {
                        color: #333333;
                        font-size: 28px;
                        font-weight: bold;

                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                    }
                    .intro {
                        font-size: 24px;
                        margin: 18px 0;
                        width: 100%;
                        
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 1;
                        -webkit-box-orient: vertical;
                    }
                    .date {
                        font-size: 22px;
                    }
                }
                .item_r {
                    width: 240px;
                    display: flex;
                    img {
                        width: 240px;
                        height: 100%;
                        border-radius: 6px;
                        object-fit: cover;
                    }
                }
            }
        }
    }
}
</style>